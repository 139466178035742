import * as React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';

import logo from '../assets/logo.jpg';
// https://unsplash.com/photos/closeup-photography-of-white-and-pink-petaled-flower-4kX1uoAAohY This image was found on unsplash

const Header = () => {
    return(
        <header>
            <nav className="navbar navbar-expand navbar-dark bg-dark">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/"><img src={logo} width="95" height="55"></img></Link>
                    <div className="collapse navbar-collapse">
                        <div className="navbar-nav">
                            <Link className="nav-link" to="/">Home</Link>
                            <Link className="nav-link" to="/cart">Cart</Link>
                            <Link className="nav-link" to="/API">API</Link>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header;