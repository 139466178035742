import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sball from '../assets/sball.jpg';
import bball from '../assets/bball.jpg';
import cleats from '../assets/cleats.jpg';
import sball2 from '../assets/sball2.jpg';
import sball3 from '../assets/sball3.jpg';
import bball2 from '../assets/bball2.jpg';
import bball3 from '../assets/bball3.jpg';
import cleats2 from '../assets/cleats2.jpg';
import cleats3 from '../assets/cleats3.jpg';

const P = styled.p`
    font-size: 19px;
    color: black;
    text-align: center;
    margin-top: 25px;
    margin-left: 55px;
    margin-right: 55px;
`;

const P2 = styled.p`
    font-size: 19px;
    font-weight: 500;
    margin-top: 15px;
    font-style: italic;
`;

const B = styled.button`
    background-color: blue;
    color: white;
    margin-right: 10px; /* Add margin between buttons */
`;

const PCOST = styled.p`
    font-size: 23px;
    font-weight: 500;
    color: black;
    margin-top: 33px;
`;

const DIVCOST = styled.div`
    margin-bottom: 79px !important;
`;

const PCOST2 = styled.p`
    font-size: 23px;
    font-weight: 500;
    color: black;
    margin-top: 25px;
`;

const DIV = styled.div`
    text-align: center;
    margin-top: 29px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px; /* Set max-width for centering */

    @media (min-width: 688px) and (max-width: 760px) {
        margin-left: 199px;
    }

    @media (min-width: 580px) and (max-width: 680px) {
        margin-left: 159px;
    }

    @media (min-width: 400px) and (max-width: 579px) {
        margin-left: 59px;
    }


`;

const DIV2 = styled.div`
    text-align: center;
`

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", position: "absolute", top: "50%", right: "-25px", zIndex: 1 }}
            onClick={onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                <path fill="none" d="M0 0h24v24H0z"/>
                <path d="M17.66 7.93L12.7 12.9l4.95 4.95 1.41-1.41-3.54-3.54 3.54-3.54z"/>
            </svg>
        </div>
    );
};

const CartItem = ({ name, price, images, handleSelectItem, handleRemoveItem, itemQuantities, itemCosts }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const imageWidth = 335; // Define image width
    const imageHeight = 275; // Define image height

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: handleSlideChange,
        nextArrow: <CustomNextArrow />
    };

    return (
        <div style={{ position: 'relative', maxWidth: imageWidth }}>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} width={imageWidth} height={imageHeight} alt={`Item ${index}`} />
                    </div>
                ))}
            </Slider>
            <P2>{name}</P2>
            <P2>Price: ${price.toFixed(2)}</P2>
            <P2>Quantity: {itemQuantities[name] || 0}</P2>
            <P2>Total Cost: ${(itemCosts[name] || 0).toFixed(2)}</P2>
            <B onClick={() => handleSelectItem(name, price)}>SELECT</B>
            <B onClick={() => handleRemoveItem(name, price)}>REMOVE</B>
        </div>
    );
};

const Cart = () => {
    const [cartItems, setCartItems] = useState([]); // State to store selected items
    const [totalCost, setTotalCost] = useState(0); // State to store total cost
    const [itemQuantities, setItemQuantities] = useState({}); // State to store item quantities
    const [itemCosts, setItemCosts] = useState({}); // State to store individual item costs

    const taxRate = 0.13; // Ontario tax rate (13%)

    // Function to handle item selection
    const handleSelectItem = (name, price) => {
        const updatedCartItems = [...cartItems, { name, price }];
        const updatedTotalCost = totalCost + price;
        const currentQuantity = itemQuantities[name] || 0;
        const updatedQuantities = { ...itemQuantities, [name]: currentQuantity + 1 };
        const updatedItemCosts = { ...itemCosts, [name]: (itemCosts[name] || 0) + price };

        setCartItems(updatedCartItems);
        setTotalCost(updatedTotalCost);
        setItemQuantities(updatedQuantities);
        setItemCosts(updatedItemCosts);
    };

    // Function to handle item removal
    const handleRemoveItem = (name, price) => {
        const updatedCartItems = cartItems.filter(item => item.name !== name);
        const updatedTotalCost = totalCost - price;
        const currentQuantity = itemQuantities[name] || 0;
        const updatedQuantities = { ...itemQuantities, [name]: currentQuantity - 1 };
        const updatedItemCosts = { ...itemCosts, [name]: (itemCosts[name] || 0) - price };

        setCartItems(updatedCartItems);
        setTotalCost(updatedTotalCost);
        setItemQuantities(updatedQuantities);
        setItemCosts(updatedItemCosts);
    };

    // Calculate final total with tax
    const finalTotal = totalCost + (totalCost * taxRate);

    return (
        <div>
            <P>This page will demonstrate the state management functionality involved in ReactJS development through the example of a shopping cart. Simply select the item you would like to purchase and the respective price will be added to the total cost calculation.</P>
            <DIV>
                <div className="row">
                    {/* Adidas Soccer Ball */}
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                        <CartItem
                            name="Adidas Soccer Ball"
                            price={49.99}
                            images={[sball, sball2, sball3]} // Using same image multiple times for demo
                            handleSelectItem={handleSelectItem}
                            handleRemoveItem={handleRemoveItem}
                            itemQuantities={itemQuantities}
                            itemCosts={itemCosts}
                        />
                    </div>
                    {/* Wilson Basketball */}
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                        <CartItem
                            name="Wilson Basketball"
                            price={39.75}
                            images={[bball, bball2, bball3]} // Using same image multiple times for demo
                            handleSelectItem={handleSelectItem}
                            handleRemoveItem={handleRemoveItem}
                            itemQuantities={itemQuantities}
                            itemCosts={itemCosts}
                        />
                    </div>
                    {/* Adidas Predator 55 Cleats */}
                    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <CartItem
                            name="Adidas Predator 55 Cleats"
                            price={115.55}
                            images={[cleats, cleats2, cleats3]} // Using same image multiple times for demo
                            handleSelectItem={handleSelectItem}
                            handleRemoveItem={handleRemoveItem}
                            itemQuantities={itemQuantities}
                            itemCosts={itemCosts}
                        />
                    </div>
                </div>
                </DIV>
            <DIV2>
                <PCOST>Subtotal: ${totalCost.toFixed(2)}</PCOST> {/* Display total cost */}
                <DIVCOST>
                    <PCOST2>Final Total with Tax: ${finalTotal.toFixed(2)}</PCOST2>
                </DIVCOST>
            </DIV2>
        </div>
    );
}

export default Cart;















