import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo2 from '../assets/logo512.jpg';

// Define a keyframe animation for spinning
const spinAnimation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

// Style the image with the animation
const SpinningImage = styled.img`
    width: 155px;
    height: 155px;
    animation: ${spinAnimation} 5s linear infinite; 
`;

const P = styled.p`
    font-size: 19px;
    color: black;
    text-align: center;
    margin-top: 29px;
    font-weight: bold;
`;

const P5 = styled.p`
    font-size: 19px;
    color: black;
    text-align: center;
    margin-top: -5px;
`;

const P2 = styled.p`
    margin-top: 23px;
    color: black;
    font-size: 18px;
`;

const P3 = styled.p`
    color: red;
    font-size: 21px;
    font-weight: 500;
`;

const DIV = styled.div`
    margin-top: 33px;
    margin-bottom: 35px;
`;

const INPUT = styled.div`
    margin-top: 19px;
`;

const Name = (props) => {
    return (
        <div>
        <div>
        <P>Hello {props.name}! Welcome to my React Application.</P>
        <P5>Enter your name below.</P5>
        <INPUT>
        <input type="text" onChange={props.changeName} value={props.name} />
        </INPUT>
        </div>
        <DIV>
        <SpinningImage src={logo2} alt="Logo" />      
        </DIV>      
        <P2>This is my multi-page ReactJS application. It consists of three pages named "Home", "Cart", and "API" respectively. Each page will display the ReactJS skills I have learnt such as:</P2>
        <P3>{props.skill}</P3>
         {/* Button to change the displayed skill */}
        </div>
    )
}

export default Name;

