// This loading component has been built to be used on multiple pages to avoid flickering upon the loading of the pages. 

const Loading = () => {

    return(
        <div>
        <div className="text-center">
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading</span>
        </div>
        </div>
        </div>
    );

}

export default Loading;