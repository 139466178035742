import * as React from 'react';
import styled from 'styled-components';

const Foot = styled.div`
    font-weight: 555;
    font-size: 14px;
    background-color: midnightblue;
    color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
`;

const Footer = () => {
    return (
        <Foot>
        <footer>&copy; React-FgotlaDe$ign$&trade;</footer>
        </Foot>
    )
}

export default Footer;