import {useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
// Above I've imported axios so that API calls can take place. Also useState to change the states on the page and the Link property. 

import Loading from '../components/Loading';
import './API.css'; // I also have some additional css linked to this page so the grid can be built properly and look nice. 

import styled from 'styled-components';

// Below I've set the various variables and styling elements I'll be using with the help of styled-components.
const H2 = styled.h2`
  color: red;
  text-align: center;
  margin-top: 15px;
`;

const P = styled.p`
  color: black;
  text-align: center;
  font-size: 19px;
  margin-bottom: 39px;
  margin-top: 19px;
`;

const P2 = styled.p`
  color: black;
  text-align: center;
  font-size: 19px;
  margin-bottom: 39px;
  margin-top: 19px;
  margin-left: 295px;
  margin-right: 295px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
}
`;

const API = () => {

    // A state is being set here for the landing page details. 
    const [ landings, setLandings] = useState ( [] );

    // Loading state is built here.
    const [ loading, setLoading ] = useState(true);

    // State to handle errors is set here. 
    const [ error, setError ] = useState(undefined);

    // The axios.get request tells the code which link to pull the data from. This will occur when the Landing component mounts. 
    useEffect( () => {
        console.log('Mounted');

        setTimeout(() => {
            axios.get('https://pokeapi.co/api/v2/pokemon?limit=151')
            .then( (response) => {
                // The response from the call is handled here. 
                console.log(response);

                const {data} = response;
                console.log(data); // This updates the state to hold the data of all the objects from the data array from the link. 

                setLandings(data.results); // This specifies one step further that the specific data we're looking for is within the results array of the data array. 

                setLoading(false);

            })
            .catch( (error) => {
                // Here is where error handling occurs. 
                console.log(error);
                console.log(error.response);

                const { status, data} = error.response;

                setError(`${status} ${data}`)

                setLoading(false);
            })
        }, 1555);
        // The value above sets the amount of time in seconds the setTimeout function loads before data shows up on the page. 
    }, []);


    console.log(landings);


    return (
        <div>
        <div>
        <P2>This page demonstrates an API call that displays a list of Pokemon gathered from https://pokeapi.co/. The list being called is specific to a particular pokedex whose information was available on the above website. You can also click the name of each Pokemon to learn more about their specific stats. The stat details were also called from a link available on the pokeapi page, which was connected between the different js files using intricate code.</P2>
        <H2>POKE API</H2>
        <P>These are our top 151 Pokemon from the Kanto region as seen in the original Pokemon series and games like FireRed and LeafGreen:</P>
        </div>

        {loading && (
            <Loading /> // This condition has the loading component show up when the page is still loading. 
        )}

        {!loading && !error && landings.length === 0 && (
            <p className="lead text-center">There is no Poxedex information to display. Your PokeSD card might be busted mate I suggest hitting the nearest PokeMart.</p> // This message will appear if there is in fact no data to be displayed. 
        )}

        {!loading && error && (
            <p className="lead text-center">{error}</p> // The same error from before is pulled here and will show up if loading is completed and there is some other error that occurs.
        )}

        {!loading && !error && landings.length > 0 && ( // This sets the condition of what is to happen if everything works properly and the data being pulled is greater than a value of zero. 
            <div className="grid">
                {landings.map((landing, index) => ( // The map method pulls the information from the array as well as the index value of each pokemon to be used later in the link on the next page so the items within the links for each individual pokemon can be pulled. 
                // When the name of each pokemon is being pulled the code has also been edited to ensure the first letter of each pokemon is a capital letter. 
                    <div key={index} className="grid-item">
                        <Link className="link" to={`/poke/${index + 1}`}><P>{landing.name.charAt(0).toUpperCase() + landing.name.slice(1)}</P></Link>
                    </div>
                    ))}
            </div>
        )}

        </div>
    );

}

export default API;