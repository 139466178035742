import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import axios from 'axios';

import Loading from '../components/Loading';
import './PokeDetails.css' // Additional css was added to this page to fix some formatting issues of the table and look relatively nicer although responsiveness is an issue. 
import styled from 'styled-components';

// Variables have been built and styled to be used through styled-components. 
const DIV = styled.div`
    text-align: center;    
    margin-top: -25px;
`;

const DIV2 = styled.div`
    text-align: center;
`;

const P = styled.p`
    text-align: center;
    background-color: beige;
    color: black;
    margin-left: 95px;
    padding: 19px;
    border: 1px solid black;
    font-weight: 555;
`;

const PokeDetails = () => {
    
    const params = useParams();
    console.log(params);
    const { pokeUrl } = useParams();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [poke, setPoke] = useState();
    const [pokeImage, setPokeImage] = useState();
    const [hpStat, setHpStat] = useState(null);
    const [attackStat, setAttackStat] = useState(null);
    const [defenseStat, setDefenseStat] = useState(null);
    const [specattackStat, setSpecattackStat] = useState(null);
    const [specdefenseStat, setSpecdefenseStat] = useState(null);
    const [speedStat, setSpeedStat] = useState(null);
    // The above are all the states that have been set to be used in coordination with the API pull to display certain data later on in the code. 

    useEffect( () => {
        
        setTimeout( () => { // The same timeout function has been set to be used on this page regarding loading. 

            axios.get(`https://pokeapi.co/api/v2/pokemon/${pokeUrl}`) // The {pokeUrl} portion of the link takes the index value from the landing page and adds it to the link so that the specific pokemon's array of data can be identified and then pulled. 
            .then((response) => {
                console.log(response);
                console.log(response.data);
                const { data } = response;
                setPoke(data);
                setPokeImage(data.sprites.front_default); // The image of each pokemon is being pulled here by specifying that data from the sprites should be pulled. 

                data.stats.map(stat => {
                    if (stat.stat.name === 'hp') {
                        setHpStat(stat.base_stat); // Here the map method is looking for the hp stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                data.stats.map(stat => {
                    if (stat.stat.name === 'attack') {
                        setAttackStat(stat.base_stat); // Here the map method is looking for the attack stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                data.stats.map(stat => {
                    if (stat.stat.name === 'defense') {
                        setDefenseStat(stat.base_stat); // Here the map method is looking for the defense stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                data.stats.map(stat => {
                    if (stat.stat.name === 'special-attack') {
                        setSpecattackStat(stat.base_stat); // Here the map method is looking for the special-attack stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                data.stats.map(stat => {
                    if (stat.stat.name === 'special-defense') {
                        setSpecdefenseStat(stat.base_stat); // Here the map method is looking for the special-defense stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                data.stats.map(stat => {
                    if (stat.stat.name === 'speed') {
                        setSpeedStat(stat.base_stat); // Here the map method is looking for the speed stat value of a given pokemon and will display the base_stat value of that stat if it is found or else it will display null. 
                    }
                    return null;
                })

                setLoading(false);
            })
            .catch((error) => { // Error handling is set here. 
                console.log(error.response);
                const { status, data } = error.response;
                setError(`${status} ${data}`);
                setLoading(false);
            });

        }, 1555); // Here is the amount of time for loading that is set. 

    }, []);

    console.log(poke);

    return (
        <div>
            {loading && ( 
                <Loading /> // This sets the loading component to appear if the page loading is true. 
            )}

            {!loading && error && (
                <div className="text-center">
                    <p className="lead">There is an error!!!</p> {/* This is the error message that will appear if something isn't working properly */}
                </div>
            )}

            {!loading && !error && poke && ( // This is what will be displayed if everything is working properly and the loading is finished and there are no errors and the information stored in the poke variable is being dipslayed. 
                <div>
                <DIV>
                <img src={pokeImage} alt={poke.name} title={poke.name} width="175" height="195" />
                </DIV>
                <table className="table"> 
                <thead>
                <P>{poke.name.charAt(0).toUpperCase() + poke.name.slice(1)}</P> {/*Once again the first letter of the name will be capitalized */}
                    <tr>
                        <th className="stats-column">Stats</th>
                        <th className="value-column">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Below all the specifc values from the API are pulled according to the variables that were set for them */}
                    <tr>
                    <td className="stats-column">HP</td>
                    <td className="value-column">{hpStat}</td>
                    </tr>
                    <tr>
                    <td className="stats-column">ATTACK</td>
                    <td className="value-column">{attackStat}</td>
                    </tr>
                    <tr>
                    <td className="stats-column">DEFENSE</td>
                    <td className="value-column">{defenseStat}</td>
                    </tr>
                    <tr>
                    <td className="stats-column">SPECIAL-ATTACK</td>
                    <td className="value-column">{specattackStat}</td>
                    </tr>
                    <tr>
                    <td className="stats-column">SPECIAL-DEFENSE</td>
                    <td className="value-column">{specdefenseStat}</td>
                    </tr>
                    <tr>
                    <td className="stats-column">SPEED</td>
                    <td className="value-column">{speedStat}</td>
                    </tr>
                </tbody>
                </table>
                <DIV2>
                    <Link to="/api" className="btn">BACK</Link> {/* Here is the back button that will take you back to the landing page which has been identified by a / */}
                </DIV2>
                </div>
            )}
        </div>
    );

}

export default PokeDetails;