import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import API from './pages/API';
import PokeDetails from './pages/PokeDetails';

import Home from './pages/Home'
import Cart from './pages/Cart';

import './App.css';

function App() {
  return (
    <div className="app">
      <div className="mb-3">
        <Header />
      </div>
    <div className="mainContent container-fluid">
    <Routes>
          {/* Route for individual Pokemon details */}
          <Route path="/poke/:pokeUrl" element={<PokeDetails />} />
          {/* Routes for other pages */}
          <Route path="/" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/api" element={<API />} />
        </Routes>
    </div>
    <div>
      <Footer />
    </div>
    </div>
  );
}

export default App;
