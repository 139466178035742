import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import Name from '../components/Name';
import img1 from '../assets/img1.jpg';
import img2 from '../assets/img2.jpg';
import img3 from '../assets/img3.jpg';

const DIVIMG = styled.div`
    text-align: center;
    margin-top: 55px;
    img {
        border-radius: 19px; 
    }
`;

const P = styled.p`
    margin-top: 75px;
    font-size: 19px;
    color: black;
    margin-left: 335px;
    margin-right: 335px;
    display: ${props => props.showContent ? 'block' : 'none'};

    @media (max-width: 768px) {
        font-size: 18px;
        margin-left: 15px;
        margin-right: 15px;
        max-width: calc(100% - 30px);
    }

    @media (min-width: 770px) and (max-width: 983px) {
        font-size: 19px;
        margin-left: 25px;
        margin-right: 25px;
        max-width: calc(100% - 50px);
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 55px;
    margin-bottom: 95px;
`;

const Button = styled.button`
    margin-top: 10px;
    display: block; /* Ensure the button is displayed as a block element */
`;

const Home = () => {
    // State to hold the input value, skills array, and whether content is shown
    const [inputValue, setInputValue] = useState('');
    const [skills, setSkills] = useState(["ReactJS Fundamentals", "State Management", "API Calls", "React-Router-DOM", "Styled-Components", "Use of Components"]);
    const [currentSkillIndex, setCurrentSkillIndex] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [buttonText, setButtonText] = useState('READ MORE!!!');

    // Function to update the input value
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    // Function to handle button click and change the displayed skill
    const changeSkill = () => {
        setCurrentSkillIndex((prevIndex) => (prevIndex + 1) % skills.length);
    };

    // Function to toggle content visibility
    const toggleContent = () => {
        setShowContent(!showContent);
        // Change button text based on content visibility
        setButtonText(showContent ? 'READ MORE!!!' : 'SHOW LESS');
    };

    return (
        <div>
            {/* Render the Name component with the input value and changeName function */}
            <Name name={inputValue} changeName={handleInputChange} skill={skills[currentSkillIndex]} />
            {/* Button to change the displayed skill */}
            <button onClick={changeSkill}>Change Skill</button>
            {/* Button to toggle content visibility */}
            <DIVIMG>
                <div className="row">
                <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <img src={img1} width={355} height={295} alt='img1' />
                </div>
                <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
                    <img src={img2} width={355} height={295} alt='img2' />
                </div>
                <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    <img src={img3} width={355} height={295} alt='img3' />
                </div>
                </div>
            </DIVIMG>
            <P showContent={showContent}>I enjoy using ReactJS to build web pages due to its efficiency and variety of features. I'd like to work with ReactJS in a front-end role or a role geared specifically towards ReactJS development.</P>
            <ButtonContainer>
            <Button onClick={toggleContent}>{buttonText}</Button>
            </ButtonContainer>
            {/* Content to be shown or hidden */}
            
        </div>
    );
}

export default Home;



